import React, { useEffect, useState } from 'react';
import './App.css';
import Header from './components/Header';
import BinaryOptions from './components/BinaryOptions';
import { Container, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, Alert } from 'reactstrap';
import Profile from './components/Profile';
import Withdraw from './components/Withdraw';
import Deposit from './components/Deposit';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [userName, setUserName] = useState('');
  const [nsgPrice, setNsgPrice] = useState('');
  const [error, setError] = useState('');
  const [tokens, setTokens] = useState([]);
  const [balances, setBalances] = useState([]);
  const [selectedToken, setSelectedToken] = useState(null);
  const [address, setAddress] = useState('');
  const [chatid, setChatId] = useState('');
  const [currentView, setCurrentView] = useState('home');

  const fetchBalances = async (chatid) => {
    if (!chatid) return;
    try {
      const response = await fetch('https://belechedev.com/webapp_bot/myapi/public/balances.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ chatid })
      });
      const data = await response.json();
      if (data.errors) {
        setError(data.errors.join(', '));
      } else {
        setBalances(data.balances);
      }
    } catch (error) {
      setError('Erro ao buscar os balances: ' + error.message);
    }
  };

  const formatNumber = (num) => {
    const isNegative = num < 0;
    const absNum = Math.abs(num);
  
    let formattedNumber;
    if (absNum < 1000) {
      formattedNumber = absNum.toString();
    } else if (absNum < 1000000) {
      formattedNumber = (absNum / 1000).toFixed(absNum >= 10000 ? 0 : 2) + 'k';
    } else if (absNum < 1000000000) {
      formattedNumber = (absNum / 1000000).toFixed(2) + 'M';
    } else if (absNum < 1000000000000) {
      formattedNumber = (absNum / 1000000000).toFixed(2) + 'BI';
    } else {
      formattedNumber = (absNum / 1000000000000).toFixed(2) + 'TRI';
    }
  
    return isNegative ? '-' + formattedNumber : formattedNumber;
  };

  useEffect(() => {
    const tg = window.Telegram?.WebApp;
    if (tg) {
      tg.expand();
      const user = tg.initDataUnsafe?.user;
      if (user) {
        setUserName(user.first_name);
        setChatId(user.id);
        registerUser(user.id, 0);
      } else {
        setUserName('Usuário');
        setChatId(1);
        registerUser(1, 0);
      }
    } else {
      console.error('Telegram WebApp script not loaded');
    }

    const fetchPrice = async () => {
      try {
        const response = await fetch('https://nste-api.belechedev.com/price.php');
        const data = await response.json();
        if (data.response === 'ok') {
          setNsgPrice(data.nsgprice);
        } else {
          setNsgPrice(0.00);
        }
      } catch (error) {
        console.error('Erro ao buscar o preço do token:', error);
      }
    };

    //fetchPrice();
    //const priceInterval = setInterval(fetchPrice, 15000);

    //return () => clearInterval(priceInterval);
  }, []);

  useEffect(() => {
    if (chatid) {
      fetchBalances(chatid);
      const balancesInterval = setInterval(() => fetchBalances(chatid), 60000);
      return () => clearInterval(balancesInterval);
    }
  }, [chatid]);

  useEffect(() => {
    if (selectedToken) {
      const updatedToken = balances.find(balance => balance.tokenid === selectedToken.tokenid);
      if (updatedToken) {
        setSelectedToken(updatedToken);
      }
    }
  }, [balances]);

  const registerUser = (chatid, upline) => {
    fetch('https://belechedev.com/webapp_bot/myapi/public/register.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ chatid, upline })
    })
    .then(response => response.json())
    .then(data => {
      if (data.errors) {
        setError(data.errors.join(', '));
      } else {
        setTokens(data.tokens);
        setBalances(data.balances);
        setAddress(data.wallet);
        if (data.balances.length > 0) {
          setSelectedToken(data.balances[0]);
        }
      }
    })
    .catch(error => setError('Erro ao registrar o usuário: ' + error.message));
  };

  const handleMenuSelect = (option) => {
      setCurrentView(option);
  };


  let ComponentToRender;
  switch (currentView) {
    case 'profile':
      ComponentToRender = <Profile chatid={chatid} balances={balances} formatNumber={formatNumber} setBalances={setBalances}/>;
      break;
    case 'withdraw':
      ComponentToRender = <Withdraw chatid={chatid} balances={balances} formatNumber={formatNumber} fetchBalances={fetchBalances} />;
      break;
    case 'deposit':
      ComponentToRender = <Deposit chatid={chatid} balances={balances} formatNumber={formatNumber} fetchBalances={fetchBalances} address={address} setAddress={setAddress}/>;
      break;
    default:
      ComponentToRender = <BinaryOptions selectedToken={selectedToken} balances={balances} telegramid={chatid} setBalances={setBalances} formatNumber={formatNumber} />;
      break;
  }

  return (
    <Container fluid className="App p-0" style={{ overflowX: 'hidden' }}>
      <Row noGutters>
        <Col>
          <Header 
            userName={userName} 
            onMenuSelect={handleMenuSelect} 
            setSelectedToken={setSelectedToken} 
            selectedToken={selectedToken} 
            balances={balances} 
            formatNumber={formatNumber} 
          />
        </Col>
      </Row>
      <Row noGutters className="mt-2">
        <Col>
          <div className="App-content p-0 m-0">
            {ComponentToRender}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default App;
