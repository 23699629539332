import React, { useState, useEffect } from 'react';
import { getCycleTimes } from './ciclo';
import { Button, Table, Modal, ModalHeader, ModalBody, ModalFooter, Input, Tooltip, Container, Row, Col, Badge, Alert } from 'reactstrap';
import { FaArrowUp, FaArrowDown, FaCircle } from 'react-icons/fa';
import Clock from './Clock';
import BtcChart from './BtcChart';

const BinaryOptions = ({ selectedToken, balances, telegramid, setBalances, formatNumber }) => {
  const [cycle, setCycle] = useState(getCycleTimes());
  const [operations, setOperations] = useState([]);
  const [amount, setAmount] = useState('');
  const [error, setError] = useState(null);
  const [roi, setRoi] = useState(80); // ROI inicial de 80%
  const [wins, setWins] = useState(0);
  const [loss, setLoss] = useState(0);
  const [draws, setDraws] = useState(0);
  const [btcPrice, setBtcPrice] = useState('');
  const [tooltipOpen, setTooltipOpen] = useState({});

  useEffect(() => {
    const interval = setInterval(() => {
      setCycle(getCycleTimes());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleRoi = () => {
      const currentTime = new Date();
      const timeElapsed = (currentTime - cycle.start) / 60000; // Tempo passado em minutos
      let newRoi;
      
      if (timeElapsed < 1) {
        newRoi = 80; // ROI de 80% no primeiro minuto
      } else if (timeElapsed >= 8) {
        newRoi = 1; // ROI de 1% no último minuto
      } else {
        newRoi = 80 - (79 * (timeElapsed - 1) / 8); // Reduzir gradativamente de 80% para 1%
      }

      setRoi(newRoi.toFixed(2));
    };

    const interval = setInterval(handleRoi, 1000);

    return () => clearInterval(interval);
  }, [cycle]);

  const fetchOperations = async () => {
    try {
      const response = await fetch('https://belechedev.com/webapp_bot/myapi/public/get_operations.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ telegramid })
      });
      const data = await response.json();
      if (data.success) {
        setOperations(data.operations);
        setWins(data.WIN);
        setLoss(data.LOSS);
        setDraws(data.DRAW);
      } else {
        setError('Erro ao buscar operações');
      }
    } catch (error) {
      setError('Erro ao buscar operações: ' + error.message);
    }
  };

  useEffect(() => {
    if (telegramid) {
      fetchOperations();
    }
  }, [telegramid]);

  useEffect(() => {
    const scheduleFetchOperations = () => {
      const now = new Date();
      const minutes = now.getMinutes();
      const nextFetchTime = new Date(now);
  
      // Ajustar o próximo horário de busca para múltiplos de 10 minutos
      const nextFetchMinute = Math.ceil((minutes + 1) / 10) * 10;
      if (nextFetchMinute >= 60) {
        nextFetchTime.setHours(nextFetchTime.getHours() + 1, 0, 4, 0); // Se passar de uma hora, ajustar a hora
      } else {
        nextFetchTime.setMinutes(nextFetchMinute, 4, 0);
      }
  
      const delay = nextFetchTime.getTime() - now.getTime();
      setTimeout(() => {
        fetchOperations();
        scheduleFetchOperations(); // Schedule next fetch
      }, delay);
    };
  
    if (telegramid) {
      scheduleFetchOperations();
    }
  }, [telegramid]);

  useEffect(() => {
    const fetchBtcPrice = async () => {
      try {
        const response = await fetch('https://api.binance.com/api/v3/ticker/price?symbol=BTCUSDT');
        const data = await response.json();
        setBtcPrice(parseFloat(data.price).toFixed(2));
      } catch (error) {
        console.error('Erro ao buscar o preço do Bitcoin:', error);
      }
    };

    fetchBtcPrice();
    const btcPriceInterval = setInterval(fetchBtcPrice, 10000);
    return () => clearInterval(btcPriceInterval);
  }, []);

  useEffect(() => {
    if (operations.length > 0) {
      const updatedOperations = operations.map(operation => {
        if (operation.status === 'OPEN') {
          const exitPrice = btcPrice;
          const isWin = operation.type === 'LONG'
            ? exitPrice > operation.pricein
            : exitPrice < operation.pricein;
          const isDraw = exitPrice === operation.pricein;
          return {
            ...operation,
            andamento: isDraw ? 'DRAW' : isWin ? 'WINNING' : 'LOSING'
          };
        } else if (operation.status === 'CLOSED') {
          return {
            ...operation,
            andamento: 'CLOSED'
          };
        }
        return operation;
      });

      setOperations(updatedOperations);
    }
  }, [btcPrice, cycle.end]);

  const handleOperation = (type) => {
    const currentTime = new Date();
    let endTime = new Date(cycle.end);
  
    const amountNumber = parseFloat(amount);
    if (isNaN(amountNumber) || amountNumber <= 0 || (selectedToken && amountNumber > selectedToken.balance)) {
      setError('Quantidade inválida ou excede o saldo disponível');
      return;
    }
  
    // Regra de ROI atualizada para ciclo de 10 minutos
    const timeLeft = (cycle.end - currentTime) / 60000;
    let newRoi;
    if (timeLeft > 9) {
      newRoi = 80;
    } else if (timeLeft < 2) {
      newRoi = 1;
    } else {
      newRoi = 80 - (79 * (9 - timeLeft) / 8);
    }
  
    setRoi(newRoi.toFixed(2));
  
    const newOperation = {
      type,
      pricein: btcPrice,
      priceout: 0,
      timein: currentTime.toISOString(),
      endtime: endTime.toISOString(),
      amount: amountNumber,
      status: 'OPEN',
      roi: newRoi,
      ticker: selectedToken ? selectedToken.ticker : null,
      result: 0
    };
  
    fetch('https://belechedev.com/webapp_bot/myapi/public/save_operation.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        telegramid,
        type,
        coin: 'BTCUSDT',
        pricein: btcPrice,
        priceout: 0,
        timein: currentTime.toISOString(),
        endtime: endTime.toISOString(),
        tokenid: selectedToken ? selectedToken.tokenid : null,
        amount: amountNumber,
        roi: newRoi,
        result: 0
      })
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setBalances(data.balances);
          setOperations(data.operations);
          setError(null);
        } else {
          setError('Erro ao salvar a operação');
        }
      })
      .catch(error => {
        setError('Erro ao salvar a operação: ' + error.message);
      });
  };  

  const getTypeIcon = (type) => (
    type === 'LONG' ? <FaArrowUp className="icon-long" /> : <FaArrowDown className="icon-short" />
  );

  const getStatusIcon = (operation) => {
    const status = operation.andamento;
    if (status === 'WINNING') {
      return (
        <Badge color="success">
          <FaCircle style={{ color: 'green' }} /> {' +'}{formatNumber((operation.amount * (operation.roi / 100)).toFixed(2))} {operation.ticker}
        </Badge>
      );
    } else if (status === 'LOSING') {
      return (
        <Badge color="danger">
          <FaCircle style={{ color: 'red' }} /> {' -'}{formatNumber(operation.amount)} {operation.ticker}
        </Badge>
      );
    } else if (status === 'DRAW') {
      return (
        <Badge color="warning">
          <FaCircle style={{ color: 'yellow' }} />
        </Badge>
      );
    } else if (status === 'CLOSED') {
      return 'CLOSED';
    }
    return null;
  };

  const getResultClass = (result) => {
    if (result > 0) {
      return 'badge bg-success'; // Classe Bootstrap para texto verde
    } else if (result < 0) {
      return 'badge bg-danger'; // Classe Bootstrap para texto vermelho
    }
    return 'badge bg-warning'; // Classe Bootstrap para texto amarelo
  };

  const toggleTooltip = (index) => {
    setTooltipOpen((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  return (
    <Container fluid>
      <Row className="mb-2">
        <Col>
          <BtcChart btcPrice={parseFloat(btcPrice)} />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          <h2 className="text-center">Operações Binárias BTC/USDT</h2>
          <div className="text-center">
            <Badge color="secondary">
              <p className="mb-0">Ciclo atual: {new Date(cycle.start).toLocaleTimeString()} - {new Date(cycle.end).toLocaleTimeString()}</p>
              <Clock />
              <p className="mb-0">ROI: {roi} %</p>
              <p className="mb-0">Preço atual do BTC: ${btcPrice}</p>
            </Badge>
          </div>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col className="text-center">
          <Button color="success" className="me-2" onClick={() => handleOperation('LONG')}>LONG</Button>
          <Button color="danger" onClick={() => handleOperation('SHORT')}>SHORT</Button>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col className="text-center">
          <Input
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            placeholder={`Quantidade de ${selectedToken ? selectedToken.ticker : ''}`}
          />
        </Col>
      </Row>
      {error && (
        <Row className="mb-2">
          <Col>
            <Alert color="danger">{error}</Alert>
          </Col>
        </Row>
      )}
      <Row className="mb-2">
        <Col className="text-center">
          <Badge color="success" className="me-2">{wins} Wins</Badge>
          <Badge color="danger" className="me-2">{loss} Loss</Badge>
          <Badge color="warning">{draws} Draws</Badge>
        </Col>
      </Row>
      {operations.length > 0 && (
        <Table striped responsive>
          <thead>
            <tr>
              <th>T</th>
              <th>PRICE IN</th>
              <th>END TIME</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {operations.map((operation, index) => (
              <tr key={index} id={`operation-${index}`}>
                <td>{getTypeIcon(operation.type)}</td>
                <td>${operation.pricein}</td>
                <td>{new Date(operation.endtime).toLocaleTimeString()}</td>
                <td>
                  <span className={operation.status === 'OPEN' ? '' : getResultClass(operation.result)}>
                    {operation.status === 'OPEN'
                      ? getStatusIcon(operation)
                      : `${formatNumber(operation.result.toFixed(2))} ${operation.ticker}`}
                  </span>
                </td>
                <Tooltip
                  placement="top"
                  isOpen={tooltipOpen[index]}
                  target={`operation-${index}`}
                  toggle={() => toggleTooltip(index)}
                >
                  <p>Tipo: {operation.type}</p>
                  <p>Price IN: ${operation.pricein}</p>
                  <p>Price Out: ${operation.priceout.toFixed(2)}</p>
                  <p>Time IN: {new Date(operation.timein).toLocaleTimeString()}</p>
                  <p>Time Out: {new Date(operation.endtime).toLocaleTimeString()}</p>
                  <p>Amount: {formatNumber(operation.amount)} {operation.ticker}</p>
                  <p>ROI: {operation.roi.toFixed(2)}%</p>
                  <p>Status: {operation.andamento}</p>
                  <p>Resultado: {formatNumber(operation.result.toFixed(2))} {operation.ticker}</p>
                </Tooltip>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Container>
  );
};

export default BinaryOptions;
