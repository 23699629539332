import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Table, Alert } from 'reactstrap';

const Deposit = ({ chatid, balances, formatNumber, fetchBalances, address, setAddress }) => {
  const [deposits, setDeposits] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    // Fetch the list of deposits
    fetch('https://belechedev.com/webapp_bot/myapi/public/get_deposits.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ telegramid: chatid })
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        setDeposits(data.deposits);
      } else {
        setError('Erro ao buscar depósitos: ' + data.message);
      }
    })
    .catch(error => setError('Erro ao buscar depósitos: ' + error.message));
  }, [chatid]);

  const handleCreateAddress = () => {
    fetch('https://belechedev.com/webapp_bot/myapi/public/create_address.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ chatid })
    })
    .then(response => response.json())
    .then(data => {
      if (data.address) {
        setAddress(data.address);
      } else {
        setError('Erro ao criar endereço: ' + data.message);
      }
    })
    .catch(error => setError('Erro ao criar endereço: ' + error.message));
  };

  return (
    <Container>
      <Row>
        <Col>
          <h2>Depósito</h2>
          {error && <Alert color="danger">{error}</Alert>}
          {address ? (
            <p>Endereço de Depósito: {address}</p>
          ) : (
            <Button color="primary" onClick={handleCreateAddress}>Criar Endereço</Button>
          )}
          <h3>Depósitos Processados</h3>
          <Table striped>
            <thead>
              <tr>
                <th>Token</th>
                <th>Amount</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {deposits.map((deposit, index) => (
                <tr key={index}>
                  <td>
                    <img
                      src={`/images/${deposit.ticker.toLowerCase()}.png`}
                      alt={deposit.ticker}
                      style={{ width: '20px', height: '20px' }}
                    />{' '}
                    {deposit.ticker}
                  </td>
                  <td>{formatNumber(deposit.amount)}</td>
                  <td>{deposit.txid ? 'Finalizado' : 'Em Andamento'}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default Deposit;
