import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import axios from 'axios';

const BtcChart = ({ btcPrice }) => {
    const [series, setSeries] = useState([]);

    useEffect(() => {
      const fetchInitialData = async () => {
        try {
          const response = await axios.get('https://api.binance.com/api/v3/klines?symbol=BTCUSDT&interval=1m&limit=30');
          const data = response.data;
  
          const formattedData = data.map(candle => ({
            x: new Date(candle[0]),
            y: [parseFloat(candle[1]), parseFloat(candle[2]), parseFloat(candle[3]), parseFloat(candle[4])] // [Open, High, Low, Close]
          }));
  
          setSeries([{
            data: formattedData
          }]);
        } catch (error) {
          console.error('Erro ao buscar dados iniciais de velas:', error);
        }
      };
  
      fetchInitialData();
    }, []);
  
    useEffect(() => {
      if (btcPrice && series.length > 0) {
        const now = new Date();
        const updatedData = [...series[0].data];
  
        if (updatedData.length > 0 && updatedData[updatedData.length - 1].x.getTime() === now.setSeconds(0, 0)) {
          updatedData[updatedData.length - 1].y[3] = btcPrice;
        } else {
          updatedData.push({
            x: now,
            y: [btcPrice, btcPrice, btcPrice, btcPrice]
          });
        }
  
        if (updatedData.length > 30) {
          updatedData.shift();
        }
  
        setSeries([{
          data: updatedData
        }]);
      }
    }, [btcPrice]);

  const options = {
    chart: {
        type: 'candlestick',
        height: '100%',
        width: '100%',
        toolbar: {
          show: false
        }
    },
    xaxis: {
      type: 'datetime'
    },
    yaxis: {
      tooltip: {
        enabled: true
      }
    }
  };

  return (
    <div className="btc-chart-container">
      <Chart
        options={options}
        series={series}
        type="candlestick"
        width="400"
        height="300"
      />
    </div>
  );
};

export default BtcChart;
