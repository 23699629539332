// src/components/Withdraw.js
import React, { useState, useEffect } from 'react';
import { Button, Input, FormGroup, Label, Row, Col, Table } from 'reactstrap';
import Web3 from 'web3';

const web3 = new Web3();

const Withdraw = ({ chatid, balances, formatNumber, fetchBalances }) => {
  const [amount, setAmount] = useState('');
  const [selectedToken, setSelectedToken] = useState(null);
  const [addressTo, setAddressTo] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [withdrawRequests, setWithdrawRequests] = useState([]);

  useEffect(() => {
    if (balances.length > 0) {
      setSelectedToken(balances[0]);
    }
  }, [balances]);

  useEffect(() => {
    fetchWithdrawRequests();
  }, [chatid]);

  const fetchWithdrawRequests = async () => {
    try {
      const response = await fetch('https://belechedev.com/webapp_bot/myapi/public/get_withdraw_requests.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ chatid })
      });
      const data = await response.json();
      if (data.success) {
        setWithdrawRequests(data.withdrawRequests);
      } else {
        setError('Erro ao buscar pedidos de saque: ' + data.message);
      }
    } catch (error) {
      setError('Erro ao buscar pedidos de saque: ' + error.message);
    }
  };

  const handleWithdraw = () => {
    if (!selectedToken || amount <= 0 || amount > selectedToken.balance) {
      setError('Quantidade inválida ou excede o saldo disponível');
      return;
    }
    if(amount < selectedToken.withdraw_min){
      setError('Quantidade abaixo do mínimo!');
      return;
    }
    if (!addressTo) {
      setError('Endereço de destino não pode estar vazio');
      return;
    }
    if (!web3.utils.isAddress(addressTo)) {
      setError('Endereço de destino inválido');
      return;
    }

    fetch('https://belechedev.com/webapp_bot/myapi/public/withdraw.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        chatid,
        tokenid: selectedToken.tokenid,
        amount: parseFloat(amount),
        addressto: addressTo
      })
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        setSuccess('Saque realizado com sucesso.');
        setError(null);
        setAmount('');
        setAddressTo('');
        fetchBalances(chatid);
        fetchWithdrawRequests(); // Atualiza a tabela de pedidos de saque
      } else {
        setError('Erro ao realizar o saque: ' + data.message);
      }
    })
    .catch(error => {
      setError('Erro ao realizar o saque: ' + error.message);
    });
  };

  return (
    <div style={{ padding: '10px' }}>
      <h2 style={{ fontSize: '1.5rem', marginBottom: '20px' }}>Saque</h2>
      <FormGroup>
        <Label for="tokenSelect">Token</Label>
        <Input type="select" id="tokenSelect" onChange={(e) => setSelectedToken(balances.find(balance => balance.tokenid === parseInt(e.target.value)))}>
          {balances.map((balance) => (
            <option key={balance.tokenid} value={balance.tokenid}>
              <img
                src={`/images/${balance.ticker.toLowerCase()}.png`}
                alt={balance.ticker}
                style={{ width: '20px', height: '20px', marginRight: '10px' }}
              />
              {balance.ticker}: {formatNumber(balance.balance)}
            </option>
          ))}
        </Input>
      </FormGroup>
      <FormGroup>
        <Label for="amount">Quantidade - (mínimo de {selectedToken !== null ? selectedToken.withdraw_min : 'N/A'} {selectedToken ? selectedToken.ticker : ''})</Label>
        <Input type="number" id="amount" value={amount} onChange={(e) => setAmount(e.target.value)} placeholder="Digite a quantidade" />
      </FormGroup>
      <FormGroup>
        <Label for="addressTo">Endereço de Destino</Label>
        <Input type="text" id="addressTo" value={addressTo} onChange={(e) => setAddressTo(e.target.value)} placeholder="Digite o endereço de destino" />
      </FormGroup>
      <Row>
        <Col xs="12">
          <Button color="primary" block onClick={handleWithdraw}>Sacar</Button>
        </Col>
      </Row>
      {error && <p className="error-message">{error}</p>}
      {success && <p className="success-message">{success}</p>}

      <h3 style={{ marginTop: '20px' }}>Pedidos de Saque</h3>
      <h6>Uma taxa de 1$ a 3$ será cobrada de todos os pedidos de saque para cobrir custos da rede.</h6>
      <Table striped>
        <thead>
          <tr>
            <th>Token</th>
            <th>Quantidade</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {withdrawRequests.map((request, index) => (
            <tr key={index}>
              <td>
                <img
                  src={`/images/${request.ticker.toLowerCase()}.png`}
                  alt={request.ticker}
                  style={{ width: '20px', height: '20px', marginRight: '10px' }}
                />
                {request.ticker}
              </td>
              <td>{formatNumber(request.amount)}</td>
              <td>{request.txid ? request.txid : 'Em Andamento'}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default Withdraw;
