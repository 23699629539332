import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Menu.css';

const Menu = ({ onOptionSelect }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const handleSelect = (option) => {
    onOptionSelect(option);
    setDropdownOpen(false); // Fechar o dropdown após a seleção
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} className="menu-dropdown">
      <DropdownToggle caret>
        Menu
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => handleSelect('home')}>Home</DropdownItem>
        <DropdownItem onClick={() => handleSelect('deposit')}>Deposit</DropdownItem>
        <DropdownItem onClick={() => handleSelect('withdraw')}>Withdraw</DropdownItem>
        <DropdownItem onClick={() => handleSelect('profile')}>Profile</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default Menu;
