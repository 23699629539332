import React, { useState, useEffect } from 'react';
import { Button, Input, FormGroup, Label, Container, Row, Col, Card, CardBody, CardTitle, Table, Alert } from 'reactstrap';

const Profile = ({ chatid, balances, formatNumber, setBalances }) => {
  const [upline, setUpline] = useState('');
  const [inputUpline, setInputUpline] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [downlines, setDownlines] = useState({});
  const [rescueSuccess, setRescueSuccess] = useState(null);
  const [rescueError, setRescueError] = useState(null);

  const rewardpercent = [15,8,4,2,1];

  useEffect(() => {
    // Fetch upline and downlines information from the server
    fetch('https://belechedev.com/webapp_bot/myapi/public/get_upline.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ chatid })
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setUpline(data.upline);
          setDownlines(data.downlines);
        } else {
          setError('Erro ao buscar o código de quem indicou: ' + data.message);
        }
      })
      .catch(error => {
        setError('Erro ao buscar o código de quem indicou: ' + error.message);
      });
  }, [chatid]);

  const handleUplineChange = (e) => {
    setInputUpline(e.target.value);
  };

  const handleUplineSubmit = () => {
    if (inputUpline === '') {
      setError('O código de quem indicou não pode estar vazio');
      return;
    }
    fetch('https://belechedev.com/webapp_bot/myapi/public/set_upline.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ chatid, upline: inputUpline })
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setUpline(inputUpline);
          setInputUpline('');
          setSuccess('Código de quem indicou salvo com sucesso.');
          setError(null);
        } else {
          setError('Erro ao salvar o código de quem indicou: ' + data.message);
        }
      })
      .catch(error => {
        setError('Erro ao salvar o código de quem indicou: ' + error.message);
      });
  };

  const handleRescue = (tokenid, ticker) => {
    fetch('https://belechedev.com/webapp_bot/myapi/public/rescue_bonus.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ chatid, tokenid, ticker })
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setBalances(data.balances);
          setRescueSuccess(`Bônus de ${ticker} resgatado com sucesso.`);
          setRescueError(null);
          // Aqui você pode atualizar o estado de balances, se necessário
        } else {
          setRescueError(`Erro ao resgatar bônus de ${ticker}: ` + data.message);
        }
      })
      .catch(error => {
        setRescueError(`Erro ao resgatar bônus de ${ticker}: ` + error.message);
      });
  };

  return (
    <Container fluid className="px-3" style={{margin: 0}}>
      <Row className="my-3">
        <Col>
          <Card>
            <CardBody>
              <CardTitle tag="h2" className="text-center">Perfil</CardTitle>
              <FormGroup>
                <Label for="referralCode">Código de Indicação</Label>
                <Input type="text" id="referralCode" value={chatid} readOnly />
              </FormGroup>
              <p>Total Rewards / Rescued:</p>
              <div>
                {balances.length > 0 && balances.map((reward, index) => (
                  <p key={index} className="d-flex align-items-center">
                    <img
                      src={`/images/${reward.ticker.toLowerCase()}.png`}
                      alt={reward.ticker}
                      style={{ width: '20px', height: '20px', marginRight: '10px' }}
                    />
                    <span>{formatNumber(reward.rewards)} {' / '} {formatNumber(reward.rewards_r)}{' '}{reward.ticker}</span>
                    <Button color="success" onClick={() => handleRescue(reward.tokenid, reward.ticker)} className="ml-auto">Resgatar</Button>
                  </p>
                ))}
              </div>
              {upline ? (
                <FormGroup>
                  <Label for="uplineCode">Seu UpLine</Label>
                  <Input type="text" id="uplineCode" value={upline} readOnly />
                </FormGroup>
              ) : (
                <FormGroup>
                  <Label for="uplineCode">Definir UpLine</Label>
                  <Input type="text" id="uplineCode" value={inputUpline} onChange={handleUplineChange} />
                  <Button color="primary" onClick={handleUplineSubmit} className="mt-2 w-100">Salvar</Button>
                </FormGroup>
              )}
              <h3 className="mt-4 text-center">Sua Rede</h3>
              {Object.keys(downlines).length > 0 ? (
                <Table bordered striped responsive className="text-center">
                  <thead>
                    <tr>
                      <th>Nível(%)</th>
                      <th>Rede</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(downlines).map(level => (
                      <tr key={level}>
                        <td>{level} ({rewardpercent[level-1]}%)</td>
                        <td>{downlines[level]}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <p className="text-center">Você ainda não possui downlines.</p>
              )}
              {error && <Alert color="danger" className="mt-4">{error}</Alert>}
              {success && <Alert color="success" className="mt-4">{success}</Alert>}
              {rescueError && <Alert color="danger" className="mt-4">{rescueError}</Alert>}
              {rescueSuccess && <Alert color="success" className="mt-4">{rescueSuccess}</Alert>}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Profile;
