import React, { useEffect, useState } from 'react';

import Menu from './Menu';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Navbar, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';

function Header({ userName, onMenuSelect, setSelectedToken, selectedToken, formatNumber, balances }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

  const handleTokenChange = (token) => {
    setSelectedToken(token);
    setDropdownOpen(false); // Fechar o dropdown após a seleção
  };

  const handleMenuSelect = (option) => {
    onMenuSelect(option);
  };

  return (
    <Navbar color="dark" dark expand="md" className="mb-0">
      <NavbarBrand href="#">
        <Menu onOptionSelect={handleMenuSelect} />
      </NavbarBrand>
      <Nav className="ml-auto" navbar>
        <NavItem>
          <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className='dropdown-menu-custom'>
            <DropdownToggle caret>
              {selectedToken ? (
                <>
                  <img
                    src={`/images/${selectedToken.ticker.toLowerCase()}.png`}
                    alt={selectedToken.ticker}
                    style={{ width: '20px', height: '20px' }}
                  />{' '}
                  {formatNumber(selectedToken.balance.toFixed(8))}
                </>
              ) : (
                'Select Token'
              )}
            </DropdownToggle>
            <DropdownMenu>
              {balances.map((balance) => (
                <DropdownItem key={balance.tokenid} onClick={() => handleTokenChange(balance)}>
                  <img
                    src={`/images/${balance.ticker.toLowerCase()}.png`}
                    alt={balance.ticker}
                    style={{ width: '20px', height: '20px' }}
                  />{' '}
                  {balance.ticker}: {formatNumber(balance.balance.toFixed(8))}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </NavItem>
      </Nav>
    </Navbar>
  );
}

export default Header;
